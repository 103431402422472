require('./src/styles/global.css');
require('@zachleat/carouscroll');
require('prismjs/prism.js');
require('prismjs/themes/prism-okaidia.min.css');
require('prismjs/plugins/line-numbers/prism-line-numbers.css');
require('prismjs/plugins/command-line/prism-command-line.css');
require('prismjs/components/prism-bash.min.js');
require('prismjs/components/prism-shell-session.min.js');
require('prismjs/components/prism-go.min.js');
require('prismjs/components/prism-javascript.min.js');
require('prismjs/components/prism-python.min.js');
require('prismjs/components/prism-sql.min.js');
require('prismjs/components/prism-hcl.min.js');
require('prismjs/components/prism-yaml.min.js');

exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
  window.scrollTo([0, 0]);

  return false;
};
