exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-about-news-jsx": () => import("./../../../src/pages/about/news.jsx" /* webpackChunkName: "component---src-pages-about-news-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-conduit-oss-jsx": () => import("./../../../src/pages/conduit-oss.jsx" /* webpackChunkName: "component---src-pages-conduit-oss-jsx" */),
  "component---src-pages-connectors-index-jsx": () => import("./../../../src/pages/connectors/index.jsx" /* webpackChunkName: "component---src-pages-connectors-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-contact-press-jsx": () => import("./../../../src/pages/contact/press.jsx" /* webpackChunkName: "component---src-pages-contact-press-jsx" */),
  "component---src-pages-contact-sales-jsx": () => import("./../../../src/pages/contact/sales.jsx" /* webpackChunkName: "component---src-pages-contact-sales-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-media-kit-jsx": () => import("./../../../src/pages/media-kit.jsx" /* webpackChunkName: "component---src-pages-media-kit-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-security-jsx": () => import("./../../../src/pages/security.jsx" /* webpackChunkName: "component---src-pages-security-jsx" */),
  "component---src-templates-blogs-by-author-index-jsx": () => import("./../../../src/templates/BlogsByAuthor/index.jsx" /* webpackChunkName: "component---src-templates-blogs-by-author-index-jsx" */),
  "component---src-templates-blogs-by-tag-index-jsx": () => import("./../../../src/templates/BlogsByTag/index.jsx" /* webpackChunkName: "component---src-templates-blogs-by-tag-index-jsx" */),
  "component---src-templates-conduit-platform-index-jsx": () => import("./../../../src/templates/ConduitPlatform/index.jsx" /* webpackChunkName: "component---src-templates-conduit-platform-index-jsx" */),
  "component---src-templates-connector-index-jsx": () => import("./../../../src/templates/Connector/index.jsx" /* webpackChunkName: "component---src-templates-connector-index-jsx" */),
  "component---src-templates-event-index-jsx": () => import("./../../../src/templates/Event/index.jsx" /* webpackChunkName: "component---src-templates-event-index-jsx" */),
  "component---src-templates-industry-index-jsx": () => import("./../../../src/templates/Industry/index.jsx" /* webpackChunkName: "component---src-templates-industry-index-jsx" */),
  "component---src-templates-legal-index-jsx": () => import("./../../../src/templates/Legal/index.jsx" /* webpackChunkName: "component---src-templates-legal-index-jsx" */),
  "component---src-templates-post-index-jsx": () => import("./../../../src/templates/Post/index.jsx" /* webpackChunkName: "component---src-templates-post-index-jsx" */),
  "component---src-templates-use-case-index-jsx": () => import("./../../../src/templates/UseCase/index.jsx" /* webpackChunkName: "component---src-templates-use-case-index-jsx" */)
}

